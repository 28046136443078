import { useEffect, useState } from "react";
import {
  SuitClubFill,
  SuitDiamondFill,
  SuitHeartFill,
  SuitSpadeFill,
} from "react-bootstrap-icons";

function Card({
  type,
  value,
  sign,
  style,
  player,
  handlePickCard,
  currentSuit,
  gameInProgress
}) {
  const [isShowing, setIsShowing] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  let isSelectable;

  if(!gameInProgress){
     isSelectable = false;

  } else{
     isSelectable =
    (type === currentSuit || type === "SPADES" || currentSuit === "start");

  }

  

  const iconMap = {
    SPADES: <SuitSpadeFill />,
    HEARTS: <SuitHeartFill style={{ fill: "red" }} />,
    DIAMONDS: <SuitDiamondFill style={{ fill: "red" }} />,
    CLUBS: <SuitClubFill />,
  };

  useEffect(() => {
    if (player) {
      setIsShowing(false);
    }

    if (player?.id === 1) {
      setIsShowing(true);
    }
  }, [player?.id, player]);

  const onhandlePickCard = () => {
    setIsShowing(isShowing);
    setIsSpinning(true);
    if (isSelectable) {
      handlePickCard(player, type, value, sign);
    }

    setIsSpinning(true);
  };

  return (
    <div
      className={`playing-card  ${isSelectable ? "" : "disabled"}`}
      onClick={onhandlePickCard}
      style={style}
    >
      {isShowing ? (
        <div className="front">
          <div className="top-corner-suit">
            <div
              className={
                type === "HEARTS" || type === "DIAMONDS" ? "text-red" : null
              }
            >
              {sign}
            </div>
            <div>{iconMap[type]}</div>
          </div>

          <div className="center-suit">{iconMap[type]}</div>

          <div className="botton-corner-suit">
            <div
              className={
                type === "HEARTS" || type === "DIAMONDS" ? "text-red" : null
              }
            >
              {sign}
            </div>
            <div>{iconMap[type]}</div>
          </div>
        </div>
      ) : (
        <div className="back">
          <img
            width={"100%"}
            alt="back of card - Hood Spades"
            src={"./hood-spades.png"}
          />
        </div>
      )}
    </div>
  );
}

export default Card;
