export const cardTypes = ["SPADES", "HEARTS", "DIAMONDS", "CLUBS"];

const createCardDeck = () => {
  const cardValues = [
    { value: 2, sign: "2" },
    { value: 3, sign: "3" },
    { value: 4, sign: "4" },
    { value: 5, sign: "5" },
    { value: 6, sign: "6" },
    { value: 7, sign: "7" },
    { value: 8, sign: "8" },
    { value: 9, sign: "9" },
    { value: 10, sign: "10" },
    { value: 11, sign: "J" },
    { value: 12, sign: "Q" },
    { value: 13, sign: "K" },
    { value: 14, sign: "A" },
    { value: 15, sign: "LJ" },
    { value: 16, sign: "BJ" },
  ];

  const allCards = cardTypes.reduce((acc, curr) => {
    for (let i = 0; i < cardValues.length; i++) {
      if (cardValues[i].sign !== "LJ" && cardValues[i].sign !== "BJ") {
        acc.push({
          type: curr,
          value: cardValues[i].value,
          sign: cardValues[i].sign,
        });
      } else {
        if (curr !== "SPADES") {
          return acc;
        } else {
          acc.push({
            type: curr,
            value: cardValues[i].value,
            sign: cardValues[i].sign,
          });
        }
      }
    }
    return acc;
  }, []);

  const removeDeuces = allCards.filter(
    (card) =>
      !(
        (card.value === 2 && card.type === "HEARTS") ||
        (card.value === 2 && card.type === "DIAMONDS")
      )
  );

  return removeDeuces;
};

export default createCardDeck;
