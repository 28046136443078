import React from "react";
import Card from "./Card";

function CardDeck({ player, handlePickCard, currentSuit, deck, gameInProgress }) {
  return (
    <div className="card-deck">
      {player?.hand.map((card, index) => (
        <Card
          key={index}
          handlePickCard={handlePickCard}
          type={card.type}
          value={card.value}
          sign={card.sign}
          player={player}
          deck={deck}
          style={{ "--index": index }}
          currentSuit={currentSuit}
          gameInProgress={gameInProgress}
        />
      ))}
    </div>
  );
}

export default CardDeck;
