import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import GameSettings from "../GameSettings";
import { Badge } from "react-bootstrap";
import { useState } from "react";
import {
  ArrowCounterclockwise,
  BarChartFill,
  Cart2,
  GearFill,
  PlayBtnFill,
  PlayFill,
  SuitSpadeFill,
  Table,
} from "react-bootstrap-icons";
import Jukebox from "../JukeBox";

const MenuSidebar = ({
  handleSettingsModal,
  handleMenuToggle,
  handleShowScoreboard,
  handleStoreModal,
  startGame,
  handleRestartGame,
  handleGameScoreChange,
  collapsed,
  isMenuShowing,
}) => {
  const [showSettings, setShowSettings] = useState(true);
  const [showRestartGame, setShowRestartGame] = useState(false);

  const handleStartGame = () => {
    setShowSettings(false);
    setShowRestartGame(true);
    startGame();
  };

  return (
    <Sidebar
    //   defaultCollapsed={collapsed}
    //   collapsed={collapsed}
      style={{
        height: "100%",
      }}
      className={collapsed ? "menu-hide" : "game-menu"}
    >
      {!isMenuShowing && (
        <Menu>
          {!!showRestartGame && (
            <>
              <MenuItem
                icon={<ArrowCounterclockwise />}
                onClick={handleRestartGame}
              >
                Restart Game
              </MenuItem>

              <MenuItem icon={<BarChartFill />} onClick={handleShowScoreboard}>
                Scoreboard
              </MenuItem>
            </>
          )}
          <>
            <MenuItem
              icon={<GearFill />}
              onClick={() => setShowSettings(!showSettings)}
            >
              Settings
            </MenuItem>
            {!!showSettings && (
              <GameSettings
                onStartGame={handleStartGame}
                handleGameScoreChange={handleGameScoreChange}
              />
            )}
          </>
          <Jukebox />
        </Menu>
      )}
    </Sidebar>
  );
};

export default MenuSidebar;
