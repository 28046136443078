import { useEffect, useState } from "react";
import "./App.css";
import CardDeck from "./components/CardDeck";
import createCardDeck from "./utils/createCardDeck";
import RoundCards from "./components/RoundCards";
import ScoreTable from "./components/ScoreTable";
import GameSettings from "./components/GameSettings";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import MenuSidebar from "./components/Menu";
import GameScoreboard from "./components/GameScoreboard";
import PlayerIcon from "./components/Player-Icon";
import { SuitSpadeFill } from "react-bootstrap-icons";

const initPlayers = [
  {
    id: 1,
    username: "Brian",
    hand: [],
    bid: 0,
    won: 0,
    points: 0,
    sandbags: 0,
    isNextToPlay: true,
    previousWinner: false,
    usedRoundTurn: false,
  },
  {
    id: 2,
    username: "Sarah",
    hand: [],
    bid: 0,
    won: 0,
    points: 0,
    sandbags: 0,
    isNextToPlay: false,
    previousWinner: false,
    usedRoundTurn: false,
  },
  {
    id: 3,
    username: "Michael",
    hand: [],
    bid: 0,
    won: 0,
    points: 0,
    sandbags: 0,
    isNextToPlay: false,
    previousWinner: false,
    usedRoundTurn: false,
  },
  {
    id: 4,
    username: "Malcolm",
    hand: [],
    bid: 0,
    won: 0,
    points: 0,
    sandbags: 0,
    isNextToPlay: false,
    previousWinner: false,
    usedRoundTurn: false,
  },
];

function App() {
  const [deck, setDeck] = useState([]);
  const [players, setPlayers] = useState(initPlayers);
  const [previousWinnerIndex, setPreviousWinnerIndex] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isScoreTableVisible, setIsScoreTableVisible] = useState(false);
  const [gameScore, setGameScore] = useState(50);
  const [showPlaceBid, setShowPlaceBid] = useState(false);
  const [bid, setBid] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const [gameInProgress, setGameInProgress] = useState(false);
  const [biddingPhaseOver, setBiddingPhaseOver] = useState(false);
  const [sandbags, setSandbags] = useState(false);
  const [winnerMessage, setWinnerMessage] = useState("");

  const [isMenuShowing, setIsMenuShowing] = useState(true);

  const [round, setRound] = useState({
    id: 1,
    cardsPlayed: 0,
    roundsPlayed: 0,
    games: 0,
    currentSuit: "start",
    roundOver: true,
    p1: { type: "", value: "", sign: "" },
    p2: { type: "", value: "", sign: "" },
    p3: { type: "", value: "", sign: "" },
    p4: { type: "", value: "", sign: "" },
  });

  const [teams, setTeams] = useState({
    team1: {
      sandbags: players[0]?.sandbags + players[2]?.sandbags,
      points: players[0]?.points + players[2]?.points,
      bids: players[0]?.bids + players[2]?.bids,
      won: players[0]?.won + players[2]?.won,
    },
    team2: {
      sandbags: players[1]?.sandbags + players[3]?.sandbags,
      points: players[1]?.points + players[3]?.points,
      bids: players[1]?.bids + players[3]?.bids,
      won: players[1]?.won + players[3]?.won,
    },
  });

  const handleSandbagsChange = (e) => {
    setSandbags(e.target.checked);
  };

  const finishHand = () => {
    if (sandbags) {
      calculateSandbagPoints();
    }
    round.roundOver = true;
    console.log("The round is done.");
  };

  const handleMenuToggle = () => {
    setCollapsed(!collapsed);
    setIsMenuShowing(!isMenuShowing);
  };

  const determineWinner = () => {
    round.currentSuit = "start";

    let played = round.roundsPlayed;
    let highestValue = 0;
    let winningPlayerKey = "";

    const hasSpades = Object.values(round).some(
      (card) => card.type === "SPADES"
    );

    if (hasSpades) {
      for (const [key, card] of Object.entries(round)) {
        if (card.type === "SPADES" && card.value > highestValue) {
          highestValue = card.value;
          winningPlayerKey = key;
        }
      }
    } else {
      for (const [key, card] of Object.entries(round)) {
        if (typeof card === "object" && card.value > highestValue) {
          highestValue = card.value;
          winningPlayerKey = key;
        }
      }
    }

    const winningPlayerId = parseInt(winningPlayerKey.substring(1));
    const winningPlayer = players.find(
      (player) => player.id === winningPlayerId
    );
    const winningPlayerIndex = players.findIndex(
      (player) => player.id === winningPlayer.id
    );

    players[winningPlayerIndex].won += 1;

    const excessBooks =
      players[winningPlayerIndex].won - players[winningPlayerIndex].bid;

    if (sandbags) {
      players[winningPlayerIndex].sandbags = excessBooks;
    }

    const points = (() => {
      if (!(winningPlayer.won >= winningPlayer.bid))
        return players[winningPlayerIndex].won * 10;
      else
        return sandbags
          ? players[winningPlayerIndex].bid * 10 + excessBooks
          : players[winningPlayerIndex].bid * 10;
    })();

    players[winningPlayerIndex].points = points;

    players[winningPlayerIndex].previousWinner = true;
    console.log("Previous winner here: ", previousWinnerIndex);

    for (let i = 0; i < players.length; i++) {
      players[i]["isNextToPlay"] = false;
      players[i]["usedRoundTurn"] = false;
    }

    players[winningPlayerIndex]["isNextToPlay"] = true;

    setRound({
      ...round,
      cardsPlayed: 0,
      roundsPlayed: (played += 1),
      p1: { type: "", value: "", sign: "" },
      p2: { type: "", value: "", sign: "" },
      p3: { type: "", value: "", sign: "" },
      p4: { type: "", value: "", sign: "" },
    });

    console.log("Here is the state of the last round: ", round);
    console.log("This player won the round: ", winningPlayer);

    if (sandbags) {
      setTeams({
        team1: {
          sandbags: players[0]?.sandbags + players[2]?.sandbags,
          points: players[0]?.points + players[2]?.points,
          bids: players[0]?.bids + players[2]?.bids,
          won: players[0]?.won + players[2]?.won,
        },
        team2: {
          sandbags: players[1]?.sandbags + players[3]?.sandbags,
          points: players[1]?.points + players[3]?.points,
          bids: players[1]?.bids + players[3]?.bids,
          won: players[1]?.won + players[3]?.won,
        },
      });
    } else {
      setTeams({
        team1: {
          points: players[0]?.points + players[2]?.points,
          bids: players[0]?.bids + players[2]?.bids,
          won: players[0]?.won + players[2]?.won,
        },
        team2: {
          points: players[1]?.points + players[3]?.points,
          bids: players[1]?.bids + players[3]?.bids,
          won: players[1]?.won + players[3]?.won,
        },
      });
    }

    setWinnerMessage(`${winningPlayer.id} took that book!`);

    setTimeout(() => setWinnerMessage(""), 2000);

    return winningPlayerIndex;
  };

  const handleRestartGame = () => {
    window.location.reload();
    // setDeck([]);
    // setPlayers(initPlayers);
    // setPreviousWinnerIndex(0);
    // setIsReady(true);
    // setRound({
    //   id: 1,
    //   cardsPlayed: 0,
    //   roundsPlayed: 0,
    //   games: 0,
    //   currentSuit: "start",
    //   roundOver: true,
    //   p1: { type: "", value: "", sign: "" },
    //   p2: { type: "", value: "", sign: "" },
    //   p3: { type: "", value: "", sign: "" },
    //   p4: { type: "", value: "", sign: "" },
    // });

    // setTeams({
    //   team1: {
    //     sandbags: players[0]?.sandbags + players[2]?.sandbags,
    //     points: players[0]?.points + players[2]?.points,
    //     bids: players[0]?.bids + players[2]?.bids,
    //     won: players[0]?.won + players[2]?.won,
    //   },
    //   team2: {
    //     sandbags: players[1]?.sandbags + players[3]?.sandbags,
    //     points: players[1]?.points + players[3]?.points,
    //     bids: players[1]?.bids + players[3]?.bids,
    //     won: players[1]?.won + players[3]?.won,
    //   },
    // });
  };

  const handleRoundEnd = async () => {
    setTimeout(() => {
      const winner = determineWinner();
      console.log("Round ended, begin autoplay.");
      if (round.roundsPlayed >= 14) {
        return;
      }
      autoplay(winner, false);
    }, 1000);
  };

  const calculateSandbagPoints = () => {
    for (let i = 0; i < teams.length; i++) {
      teams[i].points = teams[i].points - teams[i].sandbags;
    }
  };

  useEffect(() => {
    const newDeck = createCardDeck();
    shuffleCards(newDeck);
    const cards = [round.p1, round.p2, round.p3, round.p4];

    if (
      teams["team1"].points === gameScore ||
      teams["team2"].points === gameScore
    ) {
      if (teams["team1"].points === gameScore) {
        alert(`Team 1 has won the game with ${teams["team1"].points}`);
      }

      if (teams["team2"].points === gameScore) {
        alert(`Team 2 has won the game with ${teams["team2"].points}`);
      }

      setTimeout(() => window.location.reload(), 2000);
    }

    const firstCard = cards.find((card) => card.type !== "");
    if (firstCard) {
      round.currentSuit = firstCard.type;
    }

    const checkGameEnd = () => {
      if (teams[0]?.points >= 250) {
        alert("Players 1 and 3 have won the game!");
        setIsReady(false);
      } else if (teams[1]?.points >= 250) {
        alert("Players 2 and 4 have won the game!");
        setIsReady(false);
      }
    };

    checkGameEnd();

    if (round.cardsPlayed === 4) {
      handleRoundEnd();
    }

    if (round.roundsPlayed >= 14) {
      finishHand();
      setGameInProgress(false);
    }
  }, [round, players]);

  const generateBid = (index) => {
    const botPlayer = players[index];
    let bid = 0;

    bid += botPlayer.hand.filter(
      (card) => card.sign === "A" || card.sign === "BJ" || card.sign === "LJ"
    ).length;

    const kingsAndQueensCount = botPlayer.hand.filter(
      (card) => card.sign === "K  " || card.sign === "Q"
    ).length;
    bid += Math.ceil(kingsAndQueensCount / 2);

    return bid;
  };

  const handleCard = async (player, type, value, sign) => {
    await pickCard(player, type, value, sign);
    console.log("Card selected, begin autoplay. After USER player");

    setTimeout(() => {
      autoplay(1, true);
    }, 1000);
  };

  const autoplay = (startAt, fromUser) => {
    if (round.roundsPlayed >= 14) {
      finishHand();
      return;
    }

    for (let i = 0; i < players.length; i++) {
      setTimeout(
        () => {
          const pointer = (i + startAt) % players.length;
          const pointerNext = (i + (startAt + 1)) % players.length;

          if (players[pointer]?.usedRoundTurn || players[pointer].id === 1) {
            return;
          }

          console.log(
            "Is player",
            players[pointer].id,
            " ready to play?: ",
            players[pointer]?.isNextToPlay
          );
          if (players[pointer]?.isNextToPlay) {
            players[pointer]["isNextToPlay"] = false;

            handleBotAction(pointer);
            players[pointerNext]["isNextToPlay"] = true;
          }

          console.log(round.cardsPlayed);
        },
        i * 1000,
        i
      );
    }
  };

  const placeBids = () => {
    const bidders = players.filter((player) => !!player.username);

    bidders[0].bid = parseInt(bid);

    for (let i = 1; i < bidders.length; i++) {
      const generatedBid = generateBid(i);
      bidders[i].bid = generatedBid;
    }

    setShowPlaceBid(false);
    setBiddingPhaseOver(true);

    setPlayers([...players, { ...bidders }]);
    setGameInProgress(true);
  };

  const startGame = () => {
    distributeCards(players[0]);
    setShowPlaceBid(true);
    setCollapsed(true);
    setIsMenuShowing(false);
    setIsReady(true);
  };

  const distributeCards = (firstPlayer) => {
    const seats = players.filter((player) => !!player.username);

    for (let i = firstPlayer.id - 1; i < deck.length; i++) {
      const playerIndex = Math.floor(i / 13);
      seats[playerIndex].hand.push(deck[i]);
    }

    setPlayers([...players, ...seats]);

    setRound((round) => ({ ...round, roundsPlayed: 1 }));
  };

  const handleDistribution = () => {
    round.games = round.games += 1;
    startGame();
  };

  const shuffleCards = (cards) => {
    setPlayers(initPlayers);

    const newDeck = [...cards];

    let currentIndex = newDeck.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [newDeck[currentIndex], newDeck[randomIndex]] = [
        newDeck[randomIndex],
        newDeck[currentIndex],
      ];
    }

    setDeck(newDeck);
  };

  const removeCard = (cardIdx, player) => {
    player.hand.splice(cardIdx, 1);
    return player;
  };

  const pickCard = async (player, type, value, sign) => {
    const cardIndex = player.hand.findIndex(
      (c) => c.value === value && c.sign === sign
    );

    removeCard(cardIndex, player);

    let playerIdx = players.findIndex((p) => player.id === p.id);

    setPlayers((ps) => {
      ps[playerIdx]["isNextToPlay"] = false;
      ps[playerIdx]["usedRoundTurn"] = true;

      if (ps[playerIdx + 1] !== -1) {
        if (playerIdx < 3) {
          ps[playerIdx + 1]["isNextToPlay"] = true;
        } else {
          playerIdx = 0;
          ps[playerIdx]["isNextToPlay"] = true;
        }
      }

      return players;
    });

    setRound((round) => {
      const updatedObj = {
        ...round,
        [`p${player.id}`]: { type, value, sign },
        cardsPlayed: round.cardsPlayed + 1,
      };

      if (round.cardsPlayed >= 4) {
        setTimeout(() => {
          determineWinner();
        }, 1000);
        updatedObj["roundsPlayed"] = round.roundsPlayed + 1;
      }

      return updatedObj;
    });

    round.currentSuit = type;
  };

  const roundCards = Object.values(round).filter(
    (card) => typeof card === "object" && card.type && card.value && card.sign
  );

  const getValidCards = (hand) => {
    let validCards;
    if (round.currentSuit !== "start") {
      validCards = hand.filter(
        (card) => card.type === round.currentSuit || card.type === "SPADES"
      );
    } else {
      validCards = hand.filter((card) => card.type !== "SPADES");
    }

    if (validCards.length > 0) {
      const currentSuitCards = validCards.filter(
        (card) => card.type === round.currentSuit
      );

      if (currentSuitCards.length > 0) {
        validCards = currentSuitCards;
      }
    }

    console.log("Here are the valid cards as per this hand: ", validCards);
    console.log("Here is the current suit: ", round.currentSuit);

    return validCards;
  };

  const pickRandomCard = (hand) => {
    const randomIndex = Math.floor(Math.random() * hand.length);
    return hand[randomIndex];
  };

  const handleBotAction = (index) => {
    const botPlayer = players[index];
    console.log("It's player ", botPlayer.id, "'s turn!");
    let validCards = getValidCards(botPlayer.hand);
    let selectedCard;

    if (!(validCards.length > 0)) {
      selectedCard = pickRandomCard(botPlayer.hand);
    } else {
      selectedCard = pickRandomCard(validCards);
    }

    setRound((prevRound) => ({
      ...prevRound,
      [`p${botPlayer.id}`]: {
        type: selectedCard.type,
        value: selectedCard.value,
        sign: selectedCard.sign,
      },
      cardsPlayed: prevRound.cardsPlayed + 1,
    }));

    botPlayer.usedRoundTurn = true;

    round.currentSuit = selectedCard?.type || "start";

    removeCard(botPlayer.hand.indexOf(selectedCard), botPlayer);
  };

  const NewGame = () => {
    <div>
      <img alt="logo" src={"./hood-spades.png"} />
      <h1>Round {round.games} Complete</h1>
      <h2>Stats:</h2>
      Team 1 {"(Player 1 and Player 3): "}{" "}
      {players[0].points + players[2].points}
      Team 2 {"(Player 2 and Player 4): "}{" "}
      {players[1].points + players[3].points}
    </div>;
  };

  const handleGameScoreChange = (e) => {
    let score = parseInt(e.target.value);
    setGameScore(score);
  };

  return (
    <>
      <MenuSidebar
        handleRestartGame={handleRestartGame}
        handleShowScoreboard={() =>
          setIsScoreTableVisible(!isScoreTableVisible)
        }
        handleScoreboardModal={null}
        handleSettingsModal={null}
        handleStoreModal={null}
        startGame={startGame}
        handleGameScoreChange={handleGameScoreChange}
        collapsed={collapsed}
        handleSandbagsChange={handleSandbagsChange}
      />
      <Container fluid className={`App ${isReady ? "" : "not-ready"}`}>
        <Row
          className="w-100 d-flex  justify-content-center align-items-center"
          style={{
            height: "95vh",
          }}
        >
          <Button className="menu-button " onClick={handleMenuToggle}>
            <div>
              <SuitSpadeFill /> Hood Spades
            </div>
          </Button>

          <Col lg={10} className="h-100">
            {/* even row */}
            <Row md={12} className="mt-2">
              <Col md={4}>
                <GameScoreboard players={players || null} />
              </Col>

              <Col
                className="d-flex justify-content-center align-items-center"
                md={4}
              >
                {" "}
                <div className="player player-3">
                  {!!players[2]["username"] && (
                    <div className="info-container">
                      <PlayerIcon
                        username={players[2].username}
                        player={players[2]}
                        size={100}
                      />
                      <p>{`${players[2].won}/${players[2].bid}`}</p>
                    </div>
                  )}
                  {!!players[2]["username"] && (
                    <CardDeck
                      player={players[2]}
                      deck={players[2]?.hand}
                      handlePickCard={handleCard}
                      currentSuit={round.currentSuit}
                    />
                  )}
                </div>
              </Col>

              <Col md={4}>
                {showPlaceBid && (
                  <div
                    className="table-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h4>Place Bid</h4>
                    <div>{bid}</div>
                    <input
                      style={{
                        textAlign: "center",
                      }}
                      min={0}
                      max={13}
                      value={bid}
                      onChange={(e) => setBid(e.target.value)}
                      type="range"
                    ></input>
                    <Button onClick={placeBids}>Enter</Button>
                  </div>
                )}

                {biddingPhaseOver && (
                  <div
                    className="table-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h4>Player {1}</h4>
                    <p>Your Turn!</p>
                  </div>
                )}
              </Col>
            </Row>
            {/* even row */}

            <Row
              className="d-flex justify-content-center align-items-center"
              md={12}
            >
              <Col
                md={4}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="player player-2">
                  {!!players[1]["username"] && (
                    <div className="info-container">
                      <PlayerIcon
                        username={players[1].username}
                        player={players[1]}
                        size={100}
                      />
                      <p>{`${players[1].won}/${players[1].bid}`}</p>
                    </div>
                  )}
                  {!!players[1]["username"] && (
                    <CardDeck
                      player={players[1]}
                      deck={players[1]?.hand}
                      handlePickCard={handleCard}
                      currentSuit={round.currentSuit}
                    />
                  )}
                </div>
              </Col>

              <Col
                md={4}
                className="d-flex justify-content-center align-items-center"
              >
                {" "}
                <div className="players-container">
                  <div className="winning-message">
                    <strong>{winnerMessage}</strong>
                  </div>
                  <img
                    className="board-logo"
                    width={"200px"}
                    alt="back of card - Hood Spades"
                    src={"./hood-spades.png"}
                  />
                  {!gameInProgress && (
                    <div className="bid-message">
                      <strong>PLACE YOUR BID TO BEGIN</strong>
                    </div>
                  )}
                  <RoundCards cards={roundCards} />
                </div>
              </Col>

              <Col
                md={4}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="player player-4">
                  {!!players[3]["username"] && (
                    <div className="info-container">
                      <PlayerIcon
                        username={players[3].username}
                        player={players[3]}
                        size={100}
                      />

                      <p>{`${players[3].won}/${players[3].bid}`}</p>
                    </div>
                  )}
                  {!!players[3]["username"] && (
                    <CardDeck
                      player={players[3]}
                      deck={players[3]?.hand}
                      handlePickCard={handleCard}
                      currentSuit={round.currentSuit}
                    />
                  )}
                </div>
              </Col>
            </Row>

            <Row md={12}>
              <Col md={4}></Col>
              <Col
                md={4}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="player player-1">
                  {!!players[0]["username"] && (
                    <div className="info-container">
                      <div
                        className="fb-login-button"
                        data-width=""
                        data-size=""
                        data-button-type=""
                        data-layout=""
                        data-auto-logout-link="true"
                        data-use-continue-as="true"
                      ></div>
                      <p>{`${players[0].won}/${players[0].bid}`}</p>
                      <PlayerIcon
                        username={players[0].username}
                        player={players[0]}
                        size={100}
                      />
                    </div>
                  )}
                  {!!players[0]["username"] && (
                    <CardDeck
                      player={players[0]}
                      deck={players[0]?.hand}
                      handlePickCard={handleCard}
                      currentSuit={round.currentSuit}
                      gameInProgress={gameInProgress}
                    />
                  )}
                </div>
              </Col>
              <Col md={4}>
                {(!!teams["team1"].points === gameScore ||
                  !!teams["team2"].points === gameScore) && <NewGame />}
                {isScoreTableVisible && (
                  <ScoreTable
                    players={players}
                    isScoreTableVisible={isScoreTableVisible}
                    onClick={() => setIsScoreTableVisible(!isScoreTableVisible)}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
