import React, { useState } from "react";
import { Card, Badge, Form, Button } from "react-bootstrap";
import axios from "axios";

const Jukebox = () => {
  const [artistName, setArtistName] = useState("");
  const [latestAlbum, setLatestAlbum] = useState("");

  const handleArtistNameChange = (e) => {
    setArtistName(e.target.value);
  };

  const searchLatestAlbum = () => {
    axios
      .get(
        `https://itunes.apple.com/search?term=${artistName}&entity=album&limit=1&sort=recent`
      )
      .then((response) => {
        const results = response.data.results;

        if (results.length > 0) {
          const embedUrl = `https://embed.music.apple.com/us/album/${
            results[0].collectionViewUrl.split("us/album/")[1]
          }`;

          setLatestAlbum(embedUrl);
        } else {
          setLatestAlbum("");
        }
      })
      .catch((error) => {
        console.error("Error searching for latest album:", error);
      });
  };

  return (
    <div style={jukeboxStyles}>
      <Card style={cardStyles}>
        <Card.Footer>
          <Form.Group controlId="artistName">
            <Form.Label>
              Play the latest jams from your favorite artists from the Apple
              Itunes Store
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter artist name"
              value={artistName}
              onChange={handleArtistNameChange}
            />
          </Form.Group>
          <Button variant="primary" onClick={searchLatestAlbum}>
            Search Latest Album
          </Button>
          {latestAlbum && (
            <iframe
              title="Apple Music Player"
              allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
              frameBorder={0}
              height={175}
              style={{
                width: "100%",
                maxWidth: "660px",
                overflow: "hidden",
                borderRadius: "10px",
              }}
              sandbox={
                "allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
              }
              src={latestAlbum}
            ></iframe>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
};

const jukeboxStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //   height: "100vh",
  //   backgroundColor: "#654321", // Brown color
};

const cardStyles = {
  width: "400px",
  borderRadius: "8px",
};

const headerStyles = {
  display: "flex",
  justifyContent: "space-between",
  //   backgroundColor: "#f5f5f5",
  //   borderTopLeftRadius: "8px",
  //   borderTopRightRadius: "8px",
};

const circleStyles = (order) => ({
  //   width: "20px",
  //   height: "20px",
  //   borderRadius: "50%",
  //   transform: "translateY(-50%)",
  //   zIndex: 3 - order,
});

export default Jukebox;
