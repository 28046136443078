import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { createAvatar } from "@dicebear/core";
import { personas } from "@dicebear/collection";

const PlayerIcon = ({ player, username, size }) => {
  const [characters, setCharacters] = useState([]);

  const createCharacter = (name) => {
    const avatar = createAvatar(personas, {
      seed: name,
    });

    const svg = avatar.toString();

    return svg;
  };

  const [showImageSelection, setShowImageSelection] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    createCharacter(player.username)
  );

  const handleImageClick = () => {
    setShowImageSelection(true);
  };

  const handleImageSelection = (imageUrl) => {
    debugger;

    setSelectedImage(imageUrl);
    setShowImageSelection(false);
  };

  const handleSaveClick = () => {
    player["displayImageUrl"] = selectedImage;
    // Save the updated player object to localStorage or send it to the server
    // Alternatively, you can use a state management library like Redux to handle the player data
  };

  useEffect(() => {
    const newCharacter = createCharacter(username);

    setSelectedImage(newCharacter);

    const names = [
      "Brittany",
      "Tasha",
      "Whitney",
      "Ashley",
      "Lauren",
      "Rebecca",
      "Keshia",
      "Nicholas",
      "John",
      "Keith",
      "Daniel",
      "Abe",
      "Jake",
      "Dominique",
    ];

    setCharacters([...names.map((name) => createCharacter(name))]);
  }, [selectedImage, username]);

  return (
    <div className="player-icon">
      <img
        src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(
          selectedImage
        )}`}
        alt="Avatar"
        width={size} // Adjust the width as needed
        height={size} // Adjust the height as needed
        onClick={handleImageClick}
      />

      <Modal
        show={showImageSelection}
        onHide={() => setShowImageSelection(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select an Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {/* Render the list of images from the iTunes Music API */}
            {/* You can use a library like Axios to make HTTP requests */}
            {/* Store the images in localStorage or state */}
            {/* Show 25 images here */}
            {/* Example image */}
            {characters.map((character, index) => (
              <Col xs={4} key={index} className="mb-4">
                <img
                  src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(
                    character
                  )}`}
                  className={`img-thumbnail${
                    selectedImage === player?.displayImageUrl ? " selected" : ""
                  }`}
                  alt="Avatar"
                  width={200} // Adjust the width as needed
                  height={200} // Adjust the height as needed
                  onClick={() => handleImageSelection(selectedImage)}
                />
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowImageSelection(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PlayerIcon;
