import React from "react";

const GameScoreboard = ({players}) => {
  const scoreData = [
    {
      team: <b>Team 1</b>,
      score: <b>{players[0]?.points + players[2]?.points}</b>,
      sandbags: 0,

    },
    {
      team: <b>Team 2</b>,
      score: <b>{players[1]?.points + players[3]?.points}</b>,
      sandbags: 0,
    },

  ];

  return (
    <div className="table-container">
  {scoreData.map((item, index) => (
            <div key={index}>
              <dt>{item.team} - {item.score}</dt>
              <dd>Bags: {item.sandbags}</dd>
            </div>
          ))}
    </div>
  );
};

export default GameScoreboard;
