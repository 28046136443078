import { useState } from "react";
import { Button } from "react-bootstrap";

const GameSettings = ({
  newGame,
  onStartGame,
  handleGameScoreChange,
  handleSandbagsChange,
}) => {
  const [withJokers, setWithJokers] = useState(true);
  const [jj2a, setJj2a] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editable, setIsEditable] = useState(true);

  const [minimumBid, setMinimumBid] = useState(4);
  const [gameScore, setGameScore] = useState(0);

  const handleWithJokersChange = (e) => {
    setWithJokers(e.target.checked);
  };

  const handleJj2aChange = (e) => {
    setJj2a(e.target.checked);
  };

  const handleMinimumBidChange = (e) => {
    setMinimumBid(e.target.value);
  };

  const handleSaveSettings = () => {
    // Perform any save settings logic here
    // You can access the selected settings using the state values (e.g., gameScore, sandbags, withJokers, jj2a, minimumBid)
    setIsEditable(false);
    setIsReady(true);
    console.log("Settings saved!");
  };

  const handleStartGame = () => {
    setDisabled(true);
    onStartGame();
    handleSaveSettings();
  };

  return (
    <div className="settings-container">
      <table>
        <thead>
          <tr>
            <th>Setting</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Game Score - {gameScore}</td>
            <td>
              <input
                type="range"
                min={"10"}
                max={"1000"}
                value={gameScore}
                contentEditable={editable}
                disabled={disabled}
                onChange={(e) => setGameScore(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Sandbags</td>
            <td>
              <input
                type="checkbox"
                checked="true"
                contentEditable={editable}
                disabled={disabled}
                onChange={handleSandbagsChange}
              />
            </td>
          </tr>
          <tr>
            <td>With Jokers</td>
            <td>
              <input
                type="checkbox"
                checked={withJokers}
                contentEditable={editable}
                disabled={disabled}
                onChange={handleWithJokersChange}
              />
            </td>
          </tr>
          <tr>
            <td>JJ2A</td>
            <td>
              <input
                type="checkbox"
                checked={jj2a}
                contentEditable={editable}
                disabled={disabled}
                onChange={handleJj2aChange}
              />
            </td>
          </tr>
          <tr>
            <td>Minimum Bid</td>
            <td>
              <input
                type="range"
                min="0"
                max="5"
                value={minimumBid}
                contentEditable={editable}
                disabled={disabled}
                onChange={handleMinimumBidChange}
              />
              {minimumBid}
            </td>
          </tr>
        </tbody>
      </table>
      {/* {editable && ( */}
      {/* )}
      {isReady && !editable && ( */}
      <Button onClick={handleStartGame} variant="success">
        Start New Game
      </Button>
      {/* )} */}
    </div>
  );
};

export default GameSettings;
