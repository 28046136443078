import React from "react";
import { Table } from "react-bootstrap";

const ScoreTable = ({ players, gameNumber = 1 }) => {
  const scoreData = [
    {
      team: <b>Team 1</b>,
      bid: <b>{players[0]?.bid + players[2]?.bid}</b>,
      made: <b>{players[0]?.won + players[2]?.won}</b>,
      score: <b>{players[0]?.points + players[2]?.points}</b>,
      // sandbags: 1,
    },
    {
      team: "Player 1",
      bid: players[0]?.bid,
      made: players[0]?.won,
      score: players[0]?.score,
      // sandbags: 1,
    },
    {
      team: "Player 3",
      bid: players[2]?.bid,
      made: players[2]?.won,
      score: players[2]?.score,
      // sandbags: 0,
    },
    {
      team: <b>Team 2</b>,
      bid: <b>{players[1]?.bid + players[3]?.bid}</b>,
      made: <b>{players[1]?.won + players[3]?.won}</b>,
      score: <b>{players[1]?.points + players[3]?.points}</b>,
      // sandbags: 0,
    },
    {
      team: "Player 2",
      bid: players[1]?.bid,
      made: players[1]?.won,
      score: players[1]?.score,
      // sandbags: 0,
    },
    {
      team: "Player 4",
      bid: players[3]?.bid,
      made: players[3]?.won,
      score: players[3]?.score,
      // sandbags: 0,
    },
  ];

  return (
    <div className="score-table-container">
      <h2>Game {gameNumber}</h2>
      {/* <p>Total Scores: Team 1: 70 Team 2: -60 (Winner first team to 300)</p>
    <p>
      Total Sandbags: Team 1: 1 Team 2: 0 (Every 10 sandbags deduct 100 points
      from team score)
    </p> */}
      <Table responsive>
        <thead>
          <tr>
            <th>Teams</th>
            <th>Bid</th>
            <th>Made</th>
            <th>Score</th>
            <th></th>
            {/* <th>Sandbags</th> */}
          </tr>
        </thead>
        <tbody>
          {scoreData.map((item, index) => (
            <tr key={index}>
              <td>{item.team}</td>
              <td>{item.bid}</td>
              <td>{item.made}</td>
              <td>{item.score}</td>
              <td>{item.sandbags}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ScoreTable;
