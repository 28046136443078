import React from "react";
import Card from "./Card";

function RoundCards({ cards }) {
  return (
    <div className="round-cards">
      {cards.map((card, index) => (
        <Card
          key={index}
          type={card.type}
          value={card.value}
          sign={card.sign}
          style={{ transform: `translateX(calc(-50% + ${index * 90}px))` }}
        />
      ))}
    </div>
  );
}

export default RoundCards;
